.icon {
    display: inline; /* Initially hide icons */
    margin-right: .4rem;
}


.tabContent{
    height: calc(100vh - 400px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.p-tabview-nav{
    width: 100%;
}

.p-datepicker .p-component{
    width: 100px !important;
    min-width: none !important;
}

.p-unselectable-text{
    align-items: center;
    justify-content: center;
    width: 25%;
}

.p-tabview-nav-link{
    align-items: center;
    justify-content: center;
    margin: auto;

}

.p-tabview .p-tabview-nav .p-tabview-ink-bar{
    background-color: #774FFF !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.12);
    color: #774FFF !important;
    opacity: 0.8 !important;
}
  
  @media (max-width: 767px) {
    .icon {
      display: inline; /* Show icons on mobile devices */
      margin: 0;
    }
    .text {
      display: none; /* Hide text on mobile devices */
    }
    .tabContent{
        height: calc(100vh - 410px);
    }
    .p-tabview-title{
        display: none;
    }
  }

  