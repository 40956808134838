/* Company.css */
.custom-card {
  background-color: var(--bs-tertiary-bg) !important;
  border: none !important;
  border-radius: 8px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  text-decoration: none;
  color: inherit !important;
}

.custom-card:hover {
  transform: translateY(-5px);
}

.plant-image-container {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plant-image {
  max-width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.plant-details {
  flex-grow: 1;
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.plant-name {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.plant-stats {
  font-size: 1rem;
}

/* Media query for smaller screens (e.g., mobile) */
@media (max-width: 768px) {

  .plant-name {
    font-size: 1.1rem; /* Reduce font size for smaller screens */
  }

  .plant-stats {
    font-size: 0.9rem; /* Reduce font size for smaller screens */
  }
  h1 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1rem;
  }
}
