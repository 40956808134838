// custom-bootstrap-theme.scss

// Import Bootstrap's default variables
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Override primary color variables
$primary: #771FFF;           // Change this to your desired primary color
$primary-hover: #6600ff;     // Change this for the hover color
$primary-focus: #7c2bf6;     // Change this for the focus color
$primary-light: #a677ed;
$btn-primary: $primary;
$nav-pills-link-active-bg: $primary;
$custom-control-checked-bg: $primary;

.nav-pills .nav-link.active{
    color: white !important;
    background-color: $primary !important
}

.nav-pills .nav-link{
    color: $primary-light !important
}

// Import the rest of Bootstrap's SCSS
@import "~bootstrap/scss/bootstrap";